import jussikeppo from "../Assets/jussikeppo.png";
import * as React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Card() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="card">
      <div>
        <img
          className="card-img-style"
          src={jussikeppo}
          alt="judge picture"
        ></img>
      </div>

      <div className="card-text-style">
        <h2 className="card-name-style">Dr. Jussi Keppo</h2>
        <p className="card-sub-name-style">
          Professor Provost's Chair, NUS Business School
        </p>
        <br />

        <button className="readmore-button" onClick={handleOpen}>
          Read More
        </button>
        <Modal
          className="close-button"
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="popup-style">
            <CloseIcon className="close-icon" onClick={handleClose} />
            <h2>Dr. Jussi Keppo</h2>
            <h3>Professor Provost's Chair, NUS Business School</h3>

            <br />
            <p>
              Professor Keppo teaches risk management and analytics courses, and
              directs analytics executive education programmes at the National
              University of Singapore (NUS) Business School. He is also the Head
              of the Department of Analytics & Operations (DAO) at NUS Business
              School and Research Director of the Institute of Operations
              Research and Analytics (IORA) at NUS. Previously, he taught at the
              University of Michigan.
              <br />
              <br />
              He has several publications in top-tier journals such as the
              Journal of Economic Theory, Review of Economic Studies, Management
              Science, Operations Research, and Journal of Business on topics
              such as investment analysis, banking regulation, learning, and
              strategic incentives. His research has been featured also in
              numerous business and popular publications, including the Wall
              Street Journal and Fortune.
              <br />
              <br />
              Professor Keppo’s research has been supported by several Asian,
              European, and US agencies such as the National Science Foundation.
              He serves on the editorial boards of Management Science,
              Operations Research, and Journal of Risk. He has consulted several
              startups, Fortune 100 companies, and financial institutions
            </p>
          </div>
        </Modal>
      </div>

      <div></div>
    </div>
  );
}

export default Card;
