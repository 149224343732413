import QuekKhorPing from "../Assets/QuekKhorPing.png";
import * as React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Card() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="card">
      <div>
        <img
          className="card-img-style"
          src={QuekKhorPing}
          alt="judge picture"
        ></img>
      </div>

      <div className="card-text-style">
        <h2 className="card-name-style">Quek Khor Ping</h2>
        <p className="card-sub-name-style">
          Visiting Senior Fellow Deputy Director, NUS Business Analytics Center
          (BAC)
        </p>
        <br />

        <button className="readmore-button" onClick={handleOpen}>
          Read More
        </button>
        <Modal
          className="close-button"
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="popup-style">
            <CloseIcon className="close-icon" onClick={handleClose} />
            <h2>Quek Khor Ping</h2>
            <h3>
              Visiting Senior Fellow Deputy Director, NUS Business Analytics
              Center (BAC)
            </h3>

            <br />
            <p>
              Khor-Ping is currently the Deputy Director Business Analytics
              Centre in National University of Singapore and a Visiting Senior
              Fellow of the Business School in the same university. He is a
              faculty member responsible for the MSc Business Analytics
              post-graduate multidisciplinary program that is jointly
              established by School of Computing and Business School. He is also
              a member of the advisory committee of the School of Business in
              Temasek Polytechnic and was a Non-executive Director of Slate Alt,
              a crowdfunding capital platform instrumental in promoting
              deep-tech entrepreneurship.
              <br />
              <br />
              Prior to the current appointments, Khor Ping had held numerous
              leadership positions in technical, consulting, marketing and sales
              over decades of career in the Industry. He was the Director of IBM
              University Program managing relationship with the tertiary
              institutes, Director of IBM Watson Competency Centre responsible
              for the implementation of the AI technology solutions in Asia, and
              the Lab Director of IBM Cloud Lab responsible for the
              proof-of-concept, technical enablement and support of developers
              in the development of SaaS running on IBM cloud. Khor Ping's other
              professional experience includes Marketing Director of IBM
              Singapore, Global Leader of IBM Software Industrial Solutions,
              Business Unit Executive responsible for IBM Global
              Microelectronics Services as well as a pioneering consultant in
              the IBM Asia Pacific Manufacturing Supply Chain practice.
              <br />
              <br />
              Khor-Ping's international experience includes assignments in the
              US as the Global Executive responsible for the IBM services
              offerings development and in China as the AP Regional Executive
              for the Industrial Segment. Khor Ping is an IBM Certified
              Professional as well as in Artificial Intelligence Ethics &
              Governance in Singapore.
            </p>
          </div>
        </Modal>
      </div>

      <div></div>
    </div>
  );
}

export default Card;
