import React from "react";
import Navbar from "../Components/Navbar";
import subpagebanner from "../Assets/subpagebanner.png";
import event from "../Assets/event.png";
import footer from "../Assets/footer.png";

const About = () => {
  return (
    <div className="App">
      <div className="home-container">
        <Navbar />
        <img src={subpagebanner} alt="" />
      </div>
      <div className="bodycopy-container">
        <h1 className="secondary-heading">Event</h1>
        <br />

        <table className="table-style">
          <tr>
            <th className="table-style-cell3">DATE</th>
            <th className="table-style-cell3">EVENT</th>
          </tr>
          <tr className="table-style-cell1">
            <td>
              <h3>23 JAN 2025</h3>
            </td>
            <td>
              <p className="table-style-cell2">
                <h3>Kick Off</h3>
                Time: 6:00pm - 9.00pm
                <br />
                Venue: Shaw Foundation Alumni House
                <br />
                11 Kent Ridge Dr, Singapore 119244
                <br />
                <br />
                Please Register at{" "}
                <a href="https://bit.ly/NUS-GURU-kickoff">
                  https://bit.ly/NUS-GURU-kickoff
                </a>
              </p>
            </td>
          </tr>
          <tr className="table-style-cell1">
            <td>
              <h3>6 FEB 2025</h3>
            </td>
            <td>
              <p className="table-style-cell2">
                <h3>Enablement Session</h3>
                Time: 6:00pm - 9.00pm
                <br />
                Venue: Shaw Foundation Alumni House
                <br />
                11 Kent Ridge Dr, Singapore 119244
                <br />
              </p>
            </td>
          </tr>
          <tr className="table-style-cell1">
            <td>
              <h3>12 APR 2025</h3>
            </td>
            <td>
              <p className="table-style-cell2">
                <h3>Finals</h3>
                Time: 9:00am
                <br />
                Venue: Shaw Foundation Alumni House
                <br />
                11 Kent Ridge Dr, Singapore 119244
                <br />
              </p>
            </td>
          </tr>
        </table>
        <br />
        <br />
        <br />

        <img src={footer} alt="" />
      </div>
    </div>
  );
};

export default About;
