import "./App.css";
import Brief from "./Pages/Brief.js";

function App() {
  return (
    <div className="Brief">
      <Brief />
    </div>
  );
}

export default App;
