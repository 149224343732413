import GeneWuu from "../Assets/GeneWuu.png";
import * as React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",

  boxShadow: 24,
  p: 4,
};

function Card1() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="card">
      <div>
        <img className="card-img-style" src={GeneWuu} alt="judge picture"></img>
      </div>

      <div className="card-text-style">
        <h2 className="card-name-style">Gene Wuu</h2>
        <p className="card-sub-name-style">
          Guru Network Partner, SVP & CTO of UTStarcom PhD, State University of
          New York
        </p>
        <br />

        <button className="readmore-button" onClick={handleOpen}>
          Read More
        </button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="popup-style">
            <CloseIcon className="close-icon" onClick={handleClose} />

            <h2>Gene Wuu</h2>
            <h3>Partner, Guru Network </h3>
            <br />
            <p>
              Gene Wuu is a partner of Guru Network. Guru Network is a global
              leader in mobile entertainment and has a wide and diverse
              portfolio that includes Castbox, the largest pure-play podcast
              platform, and a variety of gaming and social apps.
              <br />
              <br />
              Gene Wuu was CEO of Yuantel Communications 2014-2018, and SVP of
              Borqs (NASDAQ listed) has built the Yuantel MVNO brand, and sold
              more 10 million SIM cards with 4 million of active subscribers in
              3 years. Yuantel right now is ranked top 2 among 42 MVNO
              licensees. Gene has extensive experiences in marketing and
              operations for mass market mobile products. As Borqs SVP, from
              2009-2014, Gene had been responsible for mobile business marketing
              and solutions for USA, Japan, South Asia, and Europe, and
              significant experiences in global markets.
              <br />
              <br />
              Before joining Borqs, he had served as SVP and Chief Technology
              Officer at UTStarcom, and GM and VP of Core Network Business Group
              from 2003 to 2008. He had overseen the product and business
              development of UTStarcom core network during the growing period of
              the company. Gene’s BG has successfully won businesses with strong
              footprints in tier 1 carriers in China, Philippine, India, Japan,
              European and South America.
              <br />
              <br />
              Prior to UTStarcom, Dr. Wuu had worked for Telcordia Technologies
              (formerly Bellcore, now Ericson) for 17 years focusing on Core
              network and OSS products. Dr. Wuu had been an R&D Director for 10
              years at Telcordia.
              <br />
              <br />
              Gene Wuu earned his Ph D. in Computer Science from State
              University of New York at Stony Brook. He has published many
              papers in distributed processing and database query optimization.
              USA citizen.
            </p>
          </div>
        </Modal>
      </div>

      <div></div>
    </div>
  );
}

export default Card1;
