import WuChong from "../Assets/WuChong.png";
import * as React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Card() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="card">
      <div>
        <img className="card-img-style" src={WuChong} alt="judge picture"></img>
      </div>

      <div className="card-text-style">
        <h2 className="card-name-style">Wu Chong</h2>
        <p className="card-sub-name-style">
          Guru Network Head of AI, Tsinghua University
        </p>
        <br />

        <button className="readmore-button" onClick={handleOpen}>
          Read More
        </button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="popup-style">
            <CloseIcon className="close-icon" onClick={handleClose} />

            <h2>Wu Chong</h2>

            <h3>Head of AI & BI, Guru Network </h3>
            <br />
            <p>
              Chong Wu is the Head of AI & BI at Guru, where he oversees the BI
              Platform and AI product lines, focusing on creating practical,
              user-centric AI solutions. A graduate of Tsinghua University with
              a degree in Automation, Chong has played a pivotal role in
              launching multiple AI-native products globally, now reaching tens
              of millions of users. Under his leadership, Guru has introduced a
              variety of services, including AI image generation and interactive
              chat, allowing users to enjoy the convenience and fun of AI
              technology, bringing advanced digital experiences into everyday
              life.
              <br />
              <br />
              Before joining Guru, Chong served as General Manager of Digital
              Marketing at Gridsum Technology, where he led the end-to-end
              development of digital marketing and AI data products and
              supported the company’s market strategy and customer engagement
              efforts. He also held the role of Deputy Chair for the China
              Internet Advertising Standards Joint Working Group, making
              significant contributions to the development of numerous national
              and industry standards in digital technology. Chong holds over 20
              patents in big data, AI, and digital marketing, reflecting his
              commitment to advancing technology’s practical applications and
              impact.
              <br />
              <br />
              Chong remains dedicated to developing accessible AI technologies
              that empower a diverse, global user base, helping ensure the
              benefits of AI reach users everywhere.
            </p>
          </div>
        </Modal>
      </div>

      <div></div>
    </div>
  );
}

export default Card;
