import PaulaWang from "../Assets/PaulaWang.png";
import * as React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Card() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="card">
      <div>
        <img
          className="card-img-style"
          src={PaulaWang}
          alt="judge picture"
        ></img>
      </div>

      <div className="card-text-style">
        <h2 className="card-name-style">Paula Wang</h2>
        <p className="card-sub-name-style"> Head of Google APAC</p>
        <br />

        <button className="readmore-button" onClick={handleOpen}>
          Read More
        </button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="popup-style">
            <CloseIcon className="close-icon" onClick={handleClose} />

            <h2>Paula Wang</h2>

            <h3>Head of Google APAC</h3>
            <br />
            <p>
              Paula is general manager of several multi-billion-dollar
              businesses at Google. Prior to Google, she led cloud
              transformation at Microsoft. Paula is an advocate for DEI and is
              committed to improving the representation of leaders with diverse
              backgrounds. She is bilingual and had lived in the US and China
              before moving to Singapore.
              <br />
              <br />
              <a href="https://www.linkedin.com/in/paulawang/">
                https://www.linkedin.com/in/paulawang/
              </a>
            </p>
          </div>
        </Modal>
      </div>

      <div></div>
    </div>
  );
}

export default Card;
