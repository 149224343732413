import jianfenglu from "../Assets/jianfenglu.png";
import * as React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Card() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="card">
      <div>
        <img
          className="card-img-style"
          src={jianfenglu}
          alt="judge picture"
        ></img>
      </div>

      <div className="card-text-style">
        <h2 className="card-name-style">Jianfeng Lu</h2>
        <p className="card-sub-name-style">Chairman of Wiz.AI</p>
        <br />

        <button className="readmore-button" onClick={handleOpen}>
          Read More
        </button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="popup-style">
            <CloseIcon className="close-icon" onClick={handleClose} />

            <h2>Jianfeng Lu</h2>
            <h3>Chairman of Wiz.AI</h3>

            <br />
            <p>
              Jianfeng is a serial entrepreneur. He founded Wiz.AI in 2019,
              providing conversational AI agent service and AI transformation
              for BPO contact center. He also founded One North Foundation to
              build AI community and promote AGI applications. Before moving to
              Singapore, Jianfeng was VP of Qihu 360 Inc. and head of China
              Development Center of Trend Micro Inc. He is also an active tech
              angel investor.
              <br />
              <br />
              <a href="https://www.linkedin.com/in/jianfenglu8885/">
                https://www.linkedin.com/in/jianfenglu8885/
              </a>
            </p>
          </div>
        </Modal>
      </div>

      <div></div>
    </div>
  );
}

export default Card;
