import React from "react";
import Navbar from "../Components/Navbar";
import subpagebanner from "../Assets/subpagebanner.png";
import footer from "../Assets/footer.png";
import Poster from "../Components/Posters.jsx";

const About = () => {
  return (
    <div className="App">
      <div className="home-container">
        <Navbar />
        <img src={subpagebanner} alt="" />
      </div>
      <div className="bodycopy-container">
        <h1 className="secondary-heading">Public Voting</h1>
        <br />

        <h3 className="center-text">
          Stay tuned to this page for more information on public voting! 50
          lucky winners will each receive a $100 Grab voucher. Don't miss out!
        </h3>

        {/* hidden code for when voting opens:

        <h2>Registration:</h2>
        <div class="subheader-style"></div>
        <p>
          To participate in the public voting and enter the lucky draw, please
          complete the form registration here:
        </p>
        <br />

        <a href="">
          <button className="primary-button">Vote Now</button>
        </a>
        
        <br />
        <br />
        <p>or by visiting this link: [XXX].</p>
        <br />
        <br />
        

        <h2>Public Voting Rules:</h2>
        <div class="subheader-style"></div>
        <br />
        <p>
          1.      Voting Process: The public is invited to vote for their
          favourite team’s solution.
          <br />
          <br />
          2.      Only votes for teams that make it to the Top 8 will be
          eligible for the lucky draw.
          <br />
          <br />
          3.      Each participant is allowed one vote. Multiple votes from the
          same participant will result in disqualification.
          <br />
          <br />
          4.      Members of participating teams are not permitted to vote.
          <br />
          <br />
          5.      50 lucky winners will each receive a $100 Grab voucher.
          <br />
          <br />
          6.      The $100 Grab voucher cannot be exchanged for cash or any other
          prizes.
          <br />
          <br />
          7.      The organiser's decision is final with regards to the public
          voting.
        </p>

        <br />
        <br />

        <h2>View Team Posters</h2>
        <div class="subheader-style"></div>
        <p>
          View the participating teams’ posters in the gallery below or visit
          this link: [XXXXXX]
        </p>
        
        <br />

        <Poster />

        */}

        <br />
        <br />
        <br />
        <img src={footer} alt="" />
      </div>
    </div>
  );
};
export default About;
