import React from "react";
import Navbar from "../Components/Navbar";
import subpagebanner from "../Assets/subpagebanner.png";
import footer from "../Assets/footer.png";
import evaluationcriteria from "../Assets/evaluationcriteria.png";
import GuruNetworkNUSAICTC031224 from "../Assets/GuruNetworkNUSAICTC031224.pdf";

const About = () => {
  return (
    <div className="App">
      <div className="home-container">
        <Navbar />
        <img src={subpagebanner} alt="" />
      </div>
      <div className="bodycopy-container">
        <h1 className="secondary-heading">Challenge</h1>
        <br />

        <h2>Background</h2>
        <div class="subheader-style"></div>
        <br />
        <p>
          Generative Artificial Intelligence (GenAI) has emerged as a
          transformative technology in recent years, offering unprecedented
          capabilities in content creation, data synthesis, problem-solving, and
          automation. Unlike traditional AI models that focus on prediction or
          classification, GenAI can generate novel content, such as text,
          images, audio, video, and even complex data models, based on existing
          patterns and inputs. This ability opens up a realm of possibilities
          across a broad spectrum of industries, fundamentally reshaping
          business processes, enhancing user experiences, and driving
          innovation.
          <br />
          <br />
          The applications of GenAI are rapidly expanding, driven by
          advancements in models such as GPT, DALL-E, and Midjourney. Industries
          such as education, healthcare, finance, manufacturing, entertainment,
          and logistics are already exploring the potential of GenAI to
          revolutionize operations, augment productivity, and provide
          personalized services.
          <br />
          <br />
          However, the widespread adoption of GenAI also presents challenges.
          Businesses must identify suitable use cases where the technology can
          deliver substantial value, while addressing concerns related to data
          privacy, ethical AI, and seamless integration into existing workflows.
          The NUS – Guru Analytics Innovation Challenge is an opportunity to
          push the boundaries of Generative AI and explore its potential across
          diverse industries. By participating, you will contribute to the next
          wave of AI-driven innovation, creating solutions that address
          real-world business challenges and pave the way for a future where
          Generative AI plays a central role in industry transformation. Take
          this opportunity to be part of the change and showcase your creative
          and technical skills in the field of Generative AI!
        </p>
        <br />
        <br />

        <h2>Task Detail Objectives and Deliverables</h2>
        <div class="subheader-style"></div>
        <br />
        <p>
          The NUS – Guru Analytics Innovation Challenge calls on participants to
          leverage the power of Generative AI to address real-world business
          challenges, demonstrating creativity, technical expertise, and a deep
          understanding of industry needs. The core objectives of this challenge
          are as follows:
          <br />
          <br />
          <ol>
            <li className="textbold bodycopy-format-number">
              Identify Industry Pain Points
            </li>

            <ul className="bodycopy-format-1">
              <li>
                Clearly define the pain points or challenges faced by a specific
                industry (or multiple industries) that can be effectively
                addressed using Generative AI.
              </li>

              <li>
                Analyze the current limitations or inefficiencies in the
                industry, considering aspects such as process inefficiencies,
                customer experience gaps, lack of personalization, high
                operational costs, etc.
              </li>
            </ul>
            <br />

            <li className="textbold bodycopy-format-number">
              Justify the Use of Generative AI
            </li>

            <ul className="bodycopy-format-1">
              <li>
                Provide a compelling rationale for why Generative AI is the
                ideal technology for solving the identified challenges.
              </li>

              <li>
                Highlight the unique capabilities of GenAI, such as its ability
                to:
              </li>
            </ul>

            <ul className="bodycopy-format-1">
              <li>
                Generate content and insights beyond what traditional AI models
                can achieve.
              </li>

              <li>
                Adapt to various contexts and provide personalized solutions.
              </li>

              <li>Synthesize complex data and generate creative outputs.</li>

              <li>
                Explain how Generative AI’s features can be leveraged to bring
                innovative, scalable, and impactful solutions to the problem at
                hand.
              </li>
            </ul>
            <br />

            <li className="textbold bodycopy-format-number">
              Develop a Working Prototype
            </li>

            <ul className="bodycopy-format-1">
              <li>
                Create a working prototype that effectively demonstrates the
                potential and impact of your innovative idea. The prototype
                should:
              </li>

              <li>
                Showcase the functionality of the GenAI solution in addressing
                the identified pain points.
              </li>

              <li>
                Include a user interface or an interactive component that allows
                stakeholders to experience the solution.
              </li>

              <li>
                Be developed using suitable Generative AI models (e.g., GPT,
                DALL-E, Stable Diffusion) and tools (e.g., Python, OpenAI API,
                TensorFlow, PyTorch).
              </li>

              <li>
                Explain how Generative AI’s features can be leveraged to bring
                innovative, scalable, and impactful solutions to the problem at
                hand.
              </li>
            </ul>

            <br />

            <li className="textbold bodycopy-format-number">
              Demonstrate the Business Impact
            </li>
            <ul className="bodycopy-format-1">
              <li>
                Illustrate the potential business impact of your solution,
                including:{" "}
              </li>

              <ul className="bodycopy-format">
                <li>
                  Quantifiable benefits (e.g., increased productivity, cost
                  savings, improved customer satisfaction).{" "}
                </li>

                <li>
                  Strategic advantages (e.g., competitive differentiation,
                  market expansion opportunities).{" "}
                </li>

                <li>
                  Consideration of ethical and regulatory factors to ensure
                  responsible AI usage.{" "}
                </li>
              </ul>
            </ul>
          </ol>
          <br />
          <br />
          <br />
        </p>

        <h2>Expected Deliverables:</h2>
        <div class="subheader-style"></div>
        <br />
        <ul className="bodycopy-format-2">
          <li>
            A clear definition of the industry pain points and rationale for
            selecting them.
          </li>

          <li>
            An explanation of why Generative AI is the optimal technology for
            this solution.
          </li>

          <li>
            A functional prototype that showcases the capabilities and impact of
            the proposed solution.
          </li>

          <li>
            A presentation or demo to communicate the innovation, technical
            approach, and business value of the solution.
          </li>
        </ul>
        <br />
        <br />

        <h2>First Submission Details (14 Feb to 13 Mar)</h2>
        <div class="subheader-style"></div>
        <br />

        <ol>
          <li className="textbold bodycopy-format-number">
            Executive Summary (1page)
          </li>
          <p className="bodycopy-format-number">
            Summarize the key points of your solution.
          </p>
          <br />

          <li className="textbold bodycopy-format-number">
            Solution source code
          </li>
          <p className="bodycopy-format-number">
            Submit through the official website of the competition, bundling the
            source code of any prototypes developed with the corresponding
            datasets (with the appropriate annotations).
          </p>
          <br />

          <li className="textbold bodycopy-format-number">
            Presentation slides
          </li>
          <p className="bodycopy-format-number">
            Submit through the official website of the competition. Note: Please
            package the transaction program source code and presentation slides
            into a .zip file during the submission.
          </p>
          <br />

          <li className="textbold bodycopy-format-number">
            A4 size posters (for public voting):
          </li>

          <p className="bodycopy-format-number">
            The poster should include a description of the problem statement,
            the proposed solution, and the business value creation.
            Additionally, please add your team's YouTube link that features your
            10-minute video.
          </p>

          <br />
          <li className="textbold bodycopy-format-number">10 Minutes video </li>
          <p className="bodycopy-format-number">
            Solution explanation/overview & demo Uploading video on unlisted
            Youtube link.
            <br />
            <br />
            Note: Please package the transaction program source code and
            presentation slides into a .zip file during the submission.
          </p>
        </ol>

        <br />
        <br />
        <br />

        <h2>Final Day Submission- Top 8 team (4 Apr to 9 Apr)</h2>
        <div class="subheader-style"></div>
        <br />

        <ol>
          <li className="textbold bodycopy-format-number">
            Executive Summary (1page)
          </li>
          <p className="bodycopy-format-number">
            Summarize the key points of your solution.
          </p>
          <br />

          <li className="textbold bodycopy-format-number">
            Solution source code{" "}
          </li>

          <p className="bodycopy-format-number">
            Submit through the official website of the competition, bundling the
            source code of any prototypes developed with the corresponding
            datasets (with the appropriate annotations).
          </p>
          <br />

          <li className="textbold bodycopy-format-number">
            Presentation slides
          </li>
          <p className="bodycopy-format-number">
            Submit through the official website of the competition. Note: Please
            package the transaction program source code and presentation slides
            into a .zip file during the submission.
          </p>
          <br />

          <li className="textbold bodycopy-format-number">
            Working Prototype (Optional)
          </li>

          <p className="bodycopy-format-number">
            Live demo during the Final Day presentation.
          </p>
          <br />

          <li className="textbold bodycopy-format-number">
            Top 8 presentation – 10 mins presentation 5 minutes Q&A.{" "}
          </li>

          <br />
          <p className="bodycopy-format-number">
            Note: Please package the transaction program source code and
            presentation slides into a .zip file during the submission.
          </p>
        </ol>
        <br />
        <br />
        <br />
        <h2>Evaluation Criteria</h2>
        <div class="subheader-style"></div>
        <br />
        <br />
        <img src={evaluationcriteria} className="evaluationsize" alt="" />
        <br />
        <br />
        <br />

        <h2>Presentation Guidelines</h2>
        <div class="subheader-style"></div>
        <p>
          You will need to present a working model of your solution. It should
          provide the data, codes and presentation slides. This presentation
          serves as a guide for your presentation submission. Please remember
          that you will also be judged based on the quality of the PowerPoint
          presentation.
          <br />
          <br />
          <p className="textbold">
            Here are a few guidelines for you to follow:
          </p>
          <br />
          <ul className="bodycopy-format">
            <li>Your presentation should be at most 10 mins.</li>
            <li>
              Explain the challenges that your solution will be addressing.
            </li>
            <li>Explanation of how you derive your solution.</li>
            <li>
              Demonstrate the software tools or services you use in this
              innovation challenge and their function.{" "}
            </li>
            <li>Include only essential points.</li>
            <li>Use graphics effectively.</li>
            <li>
              Make sure all the information is viewable during presentation
              mode.
            </li>

            <li>
              Give credit where credit is due. References and links should also
              be placed on the last slide
            </li>
          </ul>
        </p>
        <br />
        <br />

        <h2>Terms and Condition</h2>
        <div class="subheader-style"></div>
        <p>
          Please refer to this file: 
          <a href="https://drive.google.com/file/d/1l3ESsvHAAD0z4KOmbBmpkGZWzNTwYI-Z/view?usp=sharing">
            Terms and Conditions
          </a>
        </p>
        <br />
        <br />

        {/* hidden code for when FAQ ready:
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div class="subheader-style"></div>
        <p>FAQ (attached file)</p>
*/}

        <br />
        <br />
        <br />
        <br />
        <br />

        <img src={footer} alt="" />
      </div>
    </div>
  );
};

export default About;
