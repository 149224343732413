import JamesPang from "../Assets/JamesPang.png";
import * as React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Card() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="card">
      <div>
        <img
          className="card-img-style"
          src={JamesPang}
          alt="judge picture"
        ></img>
      </div>

      <div className="card-text-style">
        <h2 className="card-name-style">Dr. James Pang Yan</h2>
        <p className="card-sub-name-style">
          Professor Co-Director, NUS Business Analytics Center (BAC)
        </p>
        <br />

        <button className="readmore-button" onClick={handleOpen}>
          Read More
        </button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="popup-style">
            <CloseIcon className="close-icon" onClick={handleClose} />
            <h2>Dr. James Pang Yan</h2>
            <h3>Professor Co-Director, NUS Business Analytics Center (BAC)</h3>

            <br />
            <p>
              Dr. Pang Yan is currently an Professor in the Department of
              Analytics and Operations (DAO) at the National University of
              Singapore. He also serves as the Co-Director of NUS Business
              Analytics Center (NUS BAC) and the Assistant Dean (industry
              relations) at NUS Business School. His recent research focuses on
              trustworthy AI, Blockchain, and Business Analytics. He has rich
              multi-industry experience in Manufacturing, Supply
              Chain/Logistics, Finance, Healthcare, and Retail sectors. Prior to
              the current role, he was the Chief Architect in Analytics and
              Optimisation at IBM and led the IBM solution design for ASEAN
              Public Sector. He has also held key positions as Lead Architect
              and Senior Manager at IBM R&D Labs, focusing on analytics and
              optimisation product development. Earlier in his career, Dr. Pang
              contributed as a Research Scientist at Motorola Labs and Micron
              Technology, where he developed and implemented operations research
              and analytics models to enhance supply chains and manufacturing
              systems.
              <br />
              <br />
              Dr. Pang is the recipient of numerous prestigious industry and
              academic awards, including Leading Academic Data Leader by CDO
              Magazine, IBM Outstanding Technical Achievement Award (OTAA),
              Master Certified Architect in the Open Group, IBM Invention
              Plateau Award, finalist of the Andrew Fraser Prize 2007 of IMechE.
              Dr. Pang also serves on several industry advisory boards, such as
              Singapore Tourism Board Data Advisory Panel, SAP APCJ Academic
              Board, China Cloud System Pioneer Strategic Alliance, Singapore
              Computer Society (Business Analytics Chapter), IBM BAO (business
              analytics and optimisation) architecture board, IBM SDE (Software
              Defined Environment) architecture board, IBM AP Invention
              Disclosure Review Board, China 12th five-year development advisory
              board (IT chapter).
              <br />
              <br />
              Dr. Pang holds over ten patents across the United States, China,
              and Singapore and publishes extensively in top-tier AI and
              analytics journals and conferences such as ICML, CVPR, ICLR, and
              TAI. Dr. Pang holds a Ph.D. from National University of Singapore
              (NUS) jointly with Massachusetts Institute of Technology (MIT),
              and Master’s and Bachelor’s degree from Zhejiang University (ZJU),
              China.
            </p>
          </div>
        </Modal>
      </div>

      <div></div>
    </div>
  );
}

export default Card;
