import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Timeline from "./Pages/Timeline.js";
import Challenge from "./Pages/Challenge.js";
import Brief from "./Pages/Brief.js";
import Event from "./Pages/Event.js";
import Community from "./Pages/Community.js";
import Teams from "./Pages/Teams.js";
import Publicvoting from "./Pages/Publicvoting.js";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "Brief",
    element: <Brief />,
  },

  {
    path: "Timeline",
    element: <Timeline />,
  },

  {
    path: "Challenge",
    element: <Challenge />,
  },

  {
    path: "Event",
    element: <Event />,
  },

  {
    path: "Community",
    element: <Community />,
  },

  {
    path: "Teams",
    element: <Teams />,
  },
  {
    path: "Publicvoting",
    element: <Publicvoting />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
