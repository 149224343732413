import React, { useState } from "react";
import Logo from "../Assets/nusgurulogo.png";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <nav>
      <div className="nav-logo-container">
        <img src={Logo} alt="" />
      </div>
      <div className="navbar-links-container">
        <Link to="/">Brief</Link>
        <Link to="/Timeline">Timeline</Link>
        <Link to="/Challenge">Challenge</Link>
        <Link to="/Event">Event</Link>
        <Link to="/Community">Community</Link>
        <Link to="/Teams">Teams</Link>
        <Link to="/Publicvoting">Voting</Link>
      </div>

      <a href="https://forms.gle/52oKNv8obsVtLgrB6">
        <button className="primary-button">Join Now!</button>
      </a>

      <div className="navbar-menu-container">
        <MenuIcon
          className="BurgerMenuButton"
          onClick={() => setOpenMenu(true)}
        />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <div className="burgerstyle">
            <div>
              <Link to="/">Brief</Link>{" "}
            </div>
            <div>
              <Link to="/Timeline">Timeline</Link>{" "}
            </div>
            <div>
              <Link to="/Challenge">Challenge</Link>{" "}
            </div>
            <Link to="/Event">Event</Link>{" "}
            <div>
              <Link to="/Community">Community</Link>{" "}
            </div>
            <div>
              <Link to="/teams">Teams</Link>{" "}
            </div>
            <div>
              <Link to="/Publicvoting">Publicvoting</Link>{" "}
            </div>
          </div>

          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;
