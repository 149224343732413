import CalvinJiang from "../Assets/CalvinJiang.png";
import * as React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Card() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="card">
      <div>
        <img
          className="card-img-style"
          src={CalvinJiang}
          alt="judge picture"
        ></img>
      </div>

      <div className="card-text-style">
        <h2 className="card-name-style">Calvin Jiang</h2>
        <p className="card-sub-name-style">
          Guru Network CSO, Bachelor from NYU, Master from Maryland University
        </p>
        <br />

        <button className="readmore-button" onClick={handleOpen}>
          Read More
        </button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="popup-style">
            <CloseIcon className="close-icon" onClick={handleClose} />

            <h2>Calvin Jiang</h2>

            <h3>Chief Strategy Officer, Guru Network</h3>
            <br />
            <p>
              Calvin Jiang is currently the Chief Strategy Officer of Guru
              Network. He is responsible for all strategic initiatives of the
              Company, as well as all capital markets activities, including
              fundraising, investments, M&A, etc… He has been with the Company
              for over 7 years.
              <br />
              <br />
              Prior to Guru Network, Calvin has over 10 years of experience in
              various finance and accounting roles in both the US and China.
              Over 5 years were in investor relations roles in the TMT industry
              in China, including at 21Vianet (NASDAQ: VNET), Tencent Music
              (NYSE: TME), and ICR, an IR consulting firm. Prior to working in
              China, he was previously at Fannie Mae in the US, responsible for
              valuation and risk management. He also worked at Ernst & Young in
              New York in both their audit and consulting divisions. He has vast
              experience with US IPO, equity and debt financing, investor
              relations, valuation, and accounting.
              <br />
              <br />
              Calvin received his Bachelors from New York University in finance
              and accounting and Masters from University of Maryland in finance.
            </p>
          </div>
        </Modal>
      </div>

      <div></div>
    </div>
  );
}

export default Card;
