import React from "react";
import Navbar from "../Components/Navbar";
import subpagebanner from "../Assets/subpagebanner.png";
import nusgurutimelinemain from "../Assets/nusgurutimelinemain.png";
import footer from "../Assets/footer.png";

const About = () => {
  return (
    <div className="App">
      <div className="home-container">
        <Navbar />
        <img src={subpagebanner} alt="" />
      </div>
      <div className="bodycopy-container">
        <h1 className="secondary-heading">Timeline</h1>

        <img className="timeline" src={nusgurutimelinemain} alt="" />

        <img src={footer} alt="" />
      </div>
    </div>
  );
};

export default About;
