import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

export default function TitlebarBelowImageList() {
  return (
    <ImageList className="ImageList-style" sx={{ width: 1000, height: 2000 }}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=248&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={<span>by: {item.author}</span>}
            position="below"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: "https://i.imgur.com/rNZov8O.png",
    title: "Title Name 1",
    author: "john doe",
  },
  {
    img: "https://i.imgur.com/OGIqGN1.png",
    title: "Title Name 2",
    author: "jane doe",
  },
  {
    img: "https://i.imgur.com/j6Je5iY.png",
    title: "Title Name 3",
    author: "tom doe",
  },
  {
    img: "https://i.imgur.com/72Npusb.png",
    title: "Title Name 4",
    author: "chris doe",
  },
  {
    img: "https://i.imgur.com/Z0pbuPc.png",
    title: "Title Name 5",
    author: "melissa doe",
  },
  {
    img: "https://i.imgur.com/KVlSmkU.png",
    title: "Title Name 6",
    author: "mike doe",
  },
];
