import WesleyWu from "../Assets/WesleyWu.png";
import * as React from "react";
import Modal from "@mui/material/Modal";
import { colors } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Card() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="card">
      <div>
        <img
          className="card-img-style"
          src={WesleyWu}
          alt="judge picture"
        ></img>
      </div>

      <div className="card-text-style">
        <h2 className="card-name-style">Wesley Wu</h2>
        <p className="card-sub-name-style">
          Guru Network CTO, Tsinghua University
        </p>
        <br />

        <button className="readmore-button" onClick={handleOpen}>
          Read More
        </button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="popup-style">
            <CloseIcon className="close-icon" onClick={handleClose} />

            <h2>Wesley Wu</h2>

            <h3>Chief Technology Officer, Guru Network </h3>
            <br />
            <p>
              Wesley is currently the CTO of Guru Network. He leads the
              company's technological initiatives, focusing on developing
              innovative mobile applications and optimizing performance across
              platforms. With a commitment to leveraging data-driven insights,
              ensures that Guru maintains its competitive edge in the mobile
              gaming industry.
              <br />
              <br />
              Before joining Guru, Wesley was a serial entrepreneur, serving as
              CTO at DealExtreme, YinYueTai, New Oriental LeCi, Shuidichou, and
              Chief Architect at SilkChain. He has witnessed the entire
              evolution of the Chinese internet industry and gained deep
              insights into sectors like e-commerce, finance, education, video,
              and gaming. His strengths lie in identifying industry needs and
              translating them accurately into digital solutions.
              <br />
              <br />
              As a programmer, his primary passion remains in the tech domain.
              Through years of engineering practice, he has developed a
              methodology for implementing Domain-Driven Design (DDD), along
              with best practices adaptable to various products and projects.
              Recently, his focus has been on effectively engineering AI
              technologies to rapidly launch AI-native applications for business
              and end users.
            </p>
          </div>
        </Modal>
      </div>

      <div></div>
    </div>
  );
}

export default Card;
