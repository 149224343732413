import React from "react";
import Navbar from "../Components/Navbar";
import mainkv from "../Assets/mainkv.png";
import nusgurutimeline1 from "../Assets/nusgurutimeline1.png";
import prize from "../Assets/prize.png";
import footer from "../Assets/footer.png";
import { List } from "@mui/material";

const Home = () => {
  return (
    <div className="App">
      <div className="home-container">
        <Navbar />

        <div className="home-banner-container">
          <div className="home-image-section">
            <img src={mainkv} alt="" />
          </div>

          <div className="home-text-section">
            <h1 className="primary-heading">Win up to $20,000 in cash!</h1>
            <p className="home-banner-text">
              This is your chance to make a meaningful impact by leveraging
              Generative AI to solve real-world business challenges.
            </p>
            <p className="home-banner-text">
              Whether your solution targets education, healthcare, finance, or
              any other industry, the possibilities are limitless. Think
              creatively, explore unconventional approaches, and contribute to
              the future of innovation.
            </p>
            <p className="home-banner-text">
              Open to all university and polytechnic students in Singapore.
            </p>

            <a href="https://forms.gle/52oKNv8obsVtLgrB6">
              <button className="primary-button">Join Now!</button>
            </a>
          </div>
        </div>
      </div>

      <div className="bodycopy-container">
        <h2 className="secondary-heading">Problem Statement</h2>
        <br />
        <p>
          Generative AI (GenAI) is rapidly transforming industries across the
          globe. This innovation challenge calls on participants to harness the
          power of GenAI to create innovative solutions that address real-world
          business challenges. Participants are encouraged to think creatively
          and explore unconventional approaches. Your solution can target a
          specific industry, e.g., education, healthcare, finance,
          manufacturing, agriculture, IT, logistics, entertainment, tourism,
          etc., or it can span across multiple industries.
        </p>
        <br />

        <p className="textbold">Key requirements:</p>
        <br />
        <ol>
          <li className="bodycopy-format">
            Clearly define the pain points or challenges within the industry or
            industries your solution targets.{" "}
          </li>
          <li className="bodycopy-format">
            Provide a compelling rationale for why Generative AI is the ideal
            technology for developing your proposed solution.{" "}
          </li>
          <li className="bodycopy-format">
            Create a working prototype that effectively demonstrates the
            potential and impact of your innovative idea.
          </li>
        </ol>

        <br />
        <p>
          This challenge is your opportunity to push the boundaries of GenAI,
          make a meaningful impact, and contribute to the future of industry
          innovation. Join us and be part of the change!{" "}
        </p>
        <h2 className="secondary-heading nusgurutimeline1"> Timeline </h2>
        <br />
        <img src={nusgurutimeline1} className="nusgurutimeline1" alt="" />
        <br />
        <h2 className="secondary-heading">Prize</h2>

        <img src={prize} alt="" />

        <img src={footer} alt="" />
      </div>
    </div>
  );
};

export default Home;
