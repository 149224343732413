import React from "react";
import Navbar from "../Components/Navbar";
import subpagebanner from "../Assets/subpagebanner.png";
import footer from "../Assets/footer.png";

const About = () => {
  return (
    <div className="App">
      <div className="home-container">
        <Navbar />
        <img src={subpagebanner} alt="" />
      </div>
      <div className="bodycopy-container">
        <h1 className="secondary-heading">Teams</h1>
        <br />
        <br />
        <h3 className="center-text">The Top 8 team will be announced here</h3>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <img src={footer} alt="" />
      </div>
    </div>
  );
};

export default About;
